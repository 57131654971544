.App {
  text-align: center;
}

.background-video {
  object-fit: cover;
  width: 100vh;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.4;
  min-width: 100%;
  min-height: 100%;
}
