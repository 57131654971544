.navbar-top {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.4);
  background-color: #3d405b !important;
  font-family: "Markazi Text", serif;

  color: white !important;
  /* position: relative; */
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  padding-bottom: 1rem;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  color: white !important;
}

.navbar-brand,
.menu-item {
  color: white !important;
  font-family: "Markazi Text", serif;
  font-size: 1.2rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  text-align: center;
}

button.nav-burger.navbar-toggler.collapsed {
  border: none !important;
}

button:focus {
  outline: none !important;
  border: none !important;
}

.menut-item:first-child {
  margin-top: 2rem;
}

.logo-title {
  font-family: "Mea Culpa", cursive;
  font-size: 1.7rem;
}

.logo-icon {
  margin-left: 0.6rem;
}
