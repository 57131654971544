.contact-container {
  margin-top: 6.5rem;
  margin-bottom: 2.5rem;

  font-family: "Markazi Text", serif;
  font-size: 2rem;
  color: white;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 1.4rem !important;
  padding-bottom: 1.4rem !important;
}

.contact-row {
  margin-top: 2rem !important;
  margin-bottom: 2rem;
}

.contact-txt {
  /* background-color: honeydew; */
  /* width: 70%; */
  /* border-radius: 25px; */
  /* opacity: 0.6; */
  color: black;
  padding: 1rem;
  text-shadow: none;
  background-color: white;
  border-radius: 25px;
}

.imessage {
  border-radius: 25px;
  background-color: none;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;

  font-size: 1.25rem;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.5rem 1.5rem;
}

.imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

p.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
}

p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.from-me ~ p.from-me {
  margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:last-child {
  margin-bottom: 0.5rem;
}

p.from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}

p.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

p[class^="from-"].emoji::before {
  content: none;
}

.no-tail::before {
  display: none;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 1rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}

.imessage {
  font-size: 1.5rem;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.25rem 0.875rem;
}

.imessage p {
  margin: 0.5rem 0;
}

.mail-row {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  font-family: "Lato", sans-serif;
}

.whats-pointer {
  margin-right: 0.5rem;
  font-size: 2rem;
}

.whats-link {
  font-size: 3rem;
  color: #90a955;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
