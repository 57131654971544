.player-container {
  margin-top: 5.5rem;
  padding-bottom: 2.5rem;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.4);
  background-color: black;
  color: white;
  border-radius: 6%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading-div {
  padding-top: 4rem;
  font-family: "Open Sans", sans-serif;
  font-size: 3rem;
}

.greet-row {
  margin-top: 1rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  color: black;
}

.greet-span {
  font-size: 2rem;
  font-family: "Markazi Text", serif;
}

.quote-right-icon {
  margin-left: 0.5rem;
  font-size: 1.3rem;
}

.quote-left-icon {
  margin-right: 0.5rem;
  font-size: 1.3rem;
}

.star-row {
  margin-top: 1rem;
}

.empty-star-icon,
.full-star-icon {
  font-size: 2.5rem;
  color: #fcbf49;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.8rem;
}

.full-star-icon {
  animation-name: glow;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes glow {
  from {
    text-shadow: 0px 0px 1px #fff, 0px 0px 5px #fcbf49;
  }
  to {
    text-shadow: 0px 0px 10px #fff, 0px 0px 20px #fcbf49;
  }
}

.greet-title {
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  font-size: 2rem;
  font-family: "Markazi Text", serif;
  margin: 0.5rem;
}

.thank-row {
  margin-top: 1.5rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  font-size: 2rem;
  font-family: "Markazi Text", serif;
}

.send-button {
  margin: none !important;
  width: 80%;
  font-size: 1.5rem;
  font-family: "Markazi Text", serif;
  outline: none !important;
  border-inline: none !important;
  border: none !important;
  margin-bottom: 1rem;
  background-color: #0077b6 !important;
}

.button-icon {
  margin-right: 0.4rem;
}

.send-button:disabled {
  outline: none !important;
  border-inline: none !important;
  border: none !important;
  background-color: #bdbbb6 !important;
  margin-bottom: 1rem;
}

.send-button:focus,
.send-button:active {
  margin: none !important;
  width: 80%;
  font-size: 1.5rem;
  font-family: "Markazi Text", serif;
  outline: none !important;
  border-inline: none !important;
  border: none !important;
  margin-bottom: 1rem;
  background-color: #0077b6 !important;
  outline: none !important;
  box-shadow: none !important;
}

.load-spinner {
  color: white !important;
  margin-right: 0.4rem;
}

.send-button-sent {
  margin: none !important;
  width: 80%;
  font-size: 1.5rem;
  font-family: "Markazi Text", serif;
  outline: none !important;
  border-inline: none !important;
  border: none !important;
  margin-bottom: 1rem;
  background-color: #5fad56 !important;
}

.send-button-sent:focus,
.send-button-sent:active {
  margin: none !important;
  width: 80%;
  font-size: 1.5rem;
  font-family: "Markazi Text", serif;
  outline: none !important;
  border-inline: none !important;
  border: none !important;
  margin-bottom: 1rem;
  background-color: #5fad56 !important;
  outline: none !important;
  box-shadow: none !important;
}
