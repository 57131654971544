.about-container {
  margin-top: 5.5rem;
  padding-bottom: 2.5rem;
}

.logo-img {
  width: 250px;
}

.about-txt-row {
  margin-top: 0.5rem;
}

.about-txt {
  font-size: 1.2rem;
  font-family: "Markazi Text", serif;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.car-row {
  margin-top: 0.2rem;
}
